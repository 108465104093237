import React from "react";
import "./Scaffolding_on_Rent_content.css";
import { Nav, Tab } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import Services_slider_section from "../../../Common_Component/Services_slider_section/Services_slider_section";

const Scaffolding_on_Rent_content = () => {
  return (
    <>
      <section className="Scaffolding_on_Rent_content_holder common-section">
        <div className="container">
          <div className="Scaffolding_on_Rent_content">
            <div className="tabs-holder">
              <Tab.Container
                id="left-tabs-example"
                defaultActiveKey="NARROW_WIDTH"
              >
                <div className="row">
                  <div className="col-md-12">
                    <Nav variant="pills" className="tabs-container_holder">
                      <Nav.Item>
                        <Nav.Link eventKey="NARROW_WIDTH">
                          NARROW-WIDTH
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="WIDE_WIDTH">WIDE WIDTH</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <div className="col-md-12">
                    <Tab.Content className="outer-tab-content">
                      <Tab.Pane eventKey="NARROW_WIDTH">
                        <div className="NARROW_WIDTH_Contant">
                          <div className="row">
                            <div className="col-md-5">
                              <Services_slider_section />
                            </div>

                            <div className="col-md-7">
                              <div className="tab-content-holder">
                                <h3>NARROW WIDTH ALUMINIUM SCAFFOLDING</h3>
                                <p>
                                  The Narrow width aluminium scaffolding is an
                                  extremely versatile work platform built to
                                  suit all trades with low reach requirements
                                  including electricians, interior installers,
                                  plasters and sign writers. Its standard width
                                  of 0.750 meter will act as a trolley for
                                  moving equipment or a workbench on work sites.
                                </p>
                              </div>

                              <div className="inner-tabs-holder">
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="Technical_Specification"
                                >
                                  <div className="row">
                                    <div className="col-md-12">
                                      <Nav
                                        variant="pills"
                                        className="inner-tabs-container_holder"
                                      >
                                        <Nav.Item>
                                          <Nav.Link eventKey="Technical_Specification">
                                            Technical Specification
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="Advantages">
                                            Advantages
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </div>
                                    <div className="col-md-12">
                                      <Tab.Content className="inner-tab-content">
                                        <Tab.Pane eventKey="Technical_Specification">
                                          <div className="NARROW_WIDTH_Contant">
                                            <div className="inner-tab-content-holder">
                                              <div className="main-table-holder">
                                                <div className="inner-tab-heading">
                                                  <h3>
                                                    PLATFORM SPECIFICATION:
                                                  </h3>
                                                </div>
                                                <div className="table-heading-holder">
                                                  <h3>
                                                    FILM FACE ANTI-SKID PLYWOOD
                                                  </h3>
                                                </div>
                                                <div className="table-data-holder">
                                                  <Table
                                                    responsive
                                                    className="record-table-holder"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th>#</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Username</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td>1</td>
                                                        <td>Mark</td>
                                                        <td>Otto</td>
                                                        <td>@mdo</td>
                                                      </tr>
                                                      <tr>
                                                        <td>2</td>
                                                        <td>Jacob</td>
                                                        <td>Thornton</td>
                                                        <td>@fat</td>
                                                      </tr>
                                                      <tr>
                                                        <td>3</td>
                                                        <td>Larry the Bird</td>
                                                        <td>twitter</td>
                                                        <td>@twitter</td>
                                                      </tr>
                                                    </tbody>
                                                  </Table>
                                                  <Table
                                                    responsive
                                                    className="record-table-holder"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th>#</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Username</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td>1</td>
                                                        <td>Mark</td>
                                                        <td>Otto</td>
                                                        <td>@mdo</td>
                                                      </tr>
                                                      <tr>
                                                        <td>2</td>
                                                        <td>Jacob</td>
                                                        <td>Thornton</td>
                                                        <td>@fat</td>
                                                      </tr>
                                                      <tr>
                                                        <td>3</td>
                                                        <td>Larry the Bird</td>
                                                        <td>twitter</td>
                                                        <td>@twitter</td>
                                                      </tr>
                                                    </tbody>
                                                  </Table>
                                                </div>
                                              </div>

                                              <div className="main-table-holder">
                                                <div className="inner-tab-heading">
                                                  <h3>
                                                    MATERIAL CHEMICAL
                                                    COMPOSITION (IN %):
                                                  </h3>
                                                </div>

                                                <div className="table-data-holder">
                                                  <Table
                                                    responsive
                                                    className="record-table-holder"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th>Mg</th>
                                                        <th>Si</th>
                                                        <th>Mn</th>
                                                        <th>Fe</th>
                                                        <th>Cu</th>
                                                        <th>Zn</th>
                                                        <th>V</th>
                                                        <th>Ti</th>
                                                        <th>AI</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td>0.54</td>
                                                        <td>0.6</td>
                                                        <td></td>
                                                        <td>0.26</td>
                                                        <td>0.08</td>
                                                        <td>0.02</td>
                                                        <td>0.01</td>
                                                        <td>0.0178</td>
                                                        <td>98.41</td>
                                                      </tr>
                                                    </tbody>
                                                  </Table>
                                                </div>
                                              </div>

                                              <div className="main-table-holder">
                                                <div className="inner-tab-heading">
                                                  <h3>LOAD TEST:</h3>
                                                </div>

                                                <div className="description-holder">
                                                  <p>
                                                    Certified form
                                                    BEPL/2018-19/IS/M151 from
                                                    Government competent
                                                    authority for safe working
                                                    load - 250 kg.
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="Advantages">
                                          <div className="Advantages-list-holder">
                                            <ul>
                                              <li>
                                                It’s Unique And Simpler Type Of
                                                Aluminium Scaffolding
                                              </li>

                                              <li>
                                                A Very Few Material With Light
                                                Weight Gives Ideal Speed Of
                                                Installation.
                                              </li>
                                              <li>
                                                Standard Width Gives Both Indoor
                                                And Outdoor Working.
                                              </li>

                                              <li>
                                                Guardrails Frames Work As
                                                Safeguard.
                                              </li>

                                              <li>
                                                Toe- Board Will Work As Fall
                                                Arrester Of Small Parts Like
                                                Tools Form Scaffolding
                                              </li>
                                            </ul>
                                          </div>
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </div>
                                  </div>
                                </Tab.Container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="WIDE_WIDTH">
                        <div className="NARROW_WIDTH_Contant">
                          <div className="row">
                            <div className="col-md-5">
                              <Services_slider_section />
                            </div>

                            <div className="col-md-7">
                              <div className="tab-content-holder">
                                <h3>WIDE WIDTH ALUMINIUM SCAFFOLDING</h3>
                                <p>
                                  The Wide width aluminium scaffolding gives
                                  wider space to work two personal along with
                                  tools and tackles. Single scaffolding with
                                  various height availability in range of 280 mm
                                  by shifting the platform. The wider width of
                                  1.350m provides more strength & easy movement.
                                </p>
                              </div>

                              <div className="inner-tabs-holder">
                                <Tab.Container
                                  id="left-tabs-example"
                                  defaultActiveKey="Technical_Specification"
                                >
                                  <div className="row">
                                    <div className="col-md-12">
                                      <Nav
                                        variant="pills"
                                        className="inner-tabs-container_holder"
                                      >
                                        <Nav.Item>
                                          <Nav.Link eventKey="Technical_Specification">
                                            Technical Specification
                                          </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                          <Nav.Link eventKey="Advantages">
                                            Advantages
                                          </Nav.Link>
                                        </Nav.Item>
                                      </Nav>
                                    </div>
                                    <div className="col-md-12">
                                      <Tab.Content className="inner-tab-content">
                                        <Tab.Pane eventKey="Technical_Specification">
                                          <div className="NARROW_WIDTH_Contant">
                                            <div className="inner-tab-content-holder">
                                              <div className="main-table-holder">
                                                <div className="inner-tab-heading">
                                                  <h3>
                                                    PLATFORM SPECIFICATION:
                                                  </h3>
                                                </div>
                                                <div className="table-heading-holder">
                                                  <h3>
                                                    FILM FACE ANTI-SKID PLYWOOD
                                                  </h3>
                                                </div>
                                                <div className="table-data-holder">
                                                  <Table
                                                    responsive
                                                    className="record-table-holder"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th>#</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Username</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td>1</td>
                                                        <td>Mark</td>
                                                        <td>Otto</td>
                                                        <td>@mdo</td>
                                                      </tr>
                                                      <tr>
                                                        <td>2</td>
                                                        <td>Jacob</td>
                                                        <td>Thornton</td>
                                                        <td>@fat</td>
                                                      </tr>
                                                      <tr>
                                                        <td>3</td>
                                                        <td>Larry the Bird</td>
                                                        <td>twitter</td>
                                                        <td>@twitter</td>
                                                      </tr>
                                                    </tbody>
                                                  </Table>
                                                  <Table
                                                    responsive
                                                    className="record-table-holder"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th>#</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>Username</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td>1</td>
                                                        <td>Mark</td>
                                                        <td>Otto</td>
                                                        <td>@mdo</td>
                                                      </tr>
                                                      <tr>
                                                        <td>2</td>
                                                        <td>Jacob</td>
                                                        <td>Thornton</td>
                                                        <td>@fat</td>
                                                      </tr>
                                                      <tr>
                                                        <td>3</td>
                                                        <td>Larry the Bird</td>
                                                        <td>twitter</td>
                                                        <td>@twitter</td>
                                                      </tr>
                                                    </tbody>
                                                  </Table>
                                                </div>
                                              </div>

                                              <div className="main-table-holder">
                                                <div className="inner-tab-heading">
                                                  <h3>
                                                    MATERIAL CHEMICAL
                                                    COMPOSITION (IN %):
                                                  </h3>
                                                </div>

                                                <div className="table-data-holder">
                                                  <Table
                                                    responsive
                                                    className="record-table-holder"
                                                  >
                                                    <thead>
                                                      <tr>
                                                        <th>Mg</th>
                                                        <th>Si</th>
                                                        <th>Mn</th>
                                                        <th>Fe</th>
                                                        <th>Cu</th>
                                                        <th>Zn</th>
                                                        <th>V</th>
                                                        <th>Ti</th>
                                                        <th>AI</th>
                                                      </tr>
                                                    </thead>
                                                    <tbody>
                                                      <tr>
                                                        <td>0.54</td>
                                                        <td>0.6</td>
                                                        <td></td>
                                                        <td>0.26</td>
                                                        <td>0.08</td>
                                                        <td>0.02</td>
                                                        <td>0.01</td>
                                                        <td>0.0178</td>
                                                        <td>98.41</td>
                                                      </tr>
                                                    </tbody>
                                                  </Table>
                                                </div>
                                              </div>

                                              <div className="main-table-holder">
                                                <div className="inner-tab-heading">
                                                  <h3>LOAD TEST:</h3>
                                                </div>

                                                <div className="description-holder">
                                                  <p>
                                                    Certified form
                                                    BEPL/2018-19/IS/M151 from
                                                    Government competent
                                                    authority for safe working
                                                    load - 250 kg.
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="Advantages">
                                          <div className="Advantages-list-holder">
                                            <ul>
                                              <li>
                                                It’s Unique And Simpler Type Of
                                                Aluminium Scaffolding
                                              </li>

                                              <li>
                                                A Very Few Material With Light
                                                Weight Gives Ideal Speed Of
                                                Installation. Standard Width
                                                Gives Both Indoor And Outdoor
                                                Working. Guardrails Frames Work
                                                As Safeguard. Toe- Board Will
                                                Work As Fall Arrester Of Small
                                                Parts Like Tools Form
                                                Scaffolding
                                              </li>

                                              <li>
                                                It’s Unique And Simpler Type Of
                                                Aluminium Scaffolding A Very Few
                                                Material With Light Weight Gives
                                                Ideal Speed Of Installation.
                                              </li>

                                              <li>
                                                Standard Width Gives Both Indoor
                                                And Outdoor Working.
                                              </li>

                                              <li>
                                                Guardrails Frames Work As
                                                Safeguard.
                                              </li>

                                              <li>
                                                Toe- Board Will Work As Fall
                                                Arrester Of Small Parts Like
                                                Tools Form Scaffolding
                                              </li>
                                            </ul>
                                          </div>
                                        </Tab.Pane>
                                      </Tab.Content>
                                    </div>
                                  </div>
                                </Tab.Container>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </div>
              </Tab.Container>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Scaffolding_on_Rent_content;
